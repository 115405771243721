<script>
import router from "../../index"; import Cookies from 'js-cookie';
export default {
  created() {
    Cookies.remove("session");
    Cookies.remove("session_nama");
    Cookies.remove("session_id");
    Cookies.remove("session_role_id");
    Cookies.remove("reload_already");
    Cookies.remove("dateLogin");

    router.push("/login");
  },
};
</script>
